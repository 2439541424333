@media (min-width: 0px) {
  .birb1-container {
    width: 340px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    gap: 72px;
  }
}

@media (min-width: 820px) {
  .birb1-container {
    width: 560px;
  }
}

@media (min-width: 1025px) {
  .birb1-container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 86px;
  }
}
