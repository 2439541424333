@media (min-width: 0px) {
  .aboutme_container {
    margin-top: 32px;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 64px;
  }

  .aboutme_container h2 {
    margin-bottom: 32px;
  }

  .aboutme_container p {
    margin-top: 12px;
  }
}

@media (min-width: 820px) {
  .aboutme_container {
    width: 560px;
  }
}

@media (min-width: 1025px) {
  .aboutme_container {
    width: 720px;
    /* padding: 0 24px; */
    padding-top: 144px;
    margin-top: 0;
  }
}
