@media (min-width: 0px) {
  .eyeball-real {
    display: none;
  }

  .eyeball-fake {
    display: block;
  }

  .birb-container {
    transform: scale(-1, 1) translateY(132px);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
  }
}

@media (min-width: 820px) {
  .birb-container {
    position: relative;
    transform: translateY(128px);
    /* width: 100vw;
    height: 100vh; */
  }

  .eyeball-fake {
    display: none;
  }

  .eyeball-real {
    display: block;
    position: absolute;
    top: 52px;
    left: 110px;

    transform-origin: bottom;

    /* transform: rotate(270deg); */
  }
}

@media (min-width: 1025px) {
}
