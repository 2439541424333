@media (min-width: 0px) {
  .eyeball-real {
    display: none;
  }

  .eyeball-fake {
    display: block;
  }

  .birb-container {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1) translateX(192px) translateY(72px);
  }
}

@media (min-width: 650px) {
  .birb-container {
    transform: scale(-1, 1) translateX(296px) translateY(72px);
  }
}

@media (min-width: 820px) {
  .birb-container {
    display: block;
    position: relative;
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1) translateX(570px) translateY(233px);
  }

  .eyeball-fake {
    display: none;
  }

  .eyeball-real {
    display: block;
    position: absolute;
    top: 46px;
    left: 42px;

    /* transform: rotate(270deg); */
    transform-origin: bottom;
  }
}

@media (min-width: 1025px) {
  .birb-container {
    transform: translateX(750px) translateY(213px);
  }
}

@media (min-width: 1400px) {
  .birb-container {
    transform: translateX(900px) translateY(213px);
  }
}
