@import "../vars.css";

.read-more svg path {
  stroke: var(--color-green);
}

.arrow1,
.arrow2,
.arrow3 {
  transition: all 0.6s cubic-bezier(0.05, 0.25, 0.4, 1);
  width: 4px;
  height: 8px;
}

.arrow1 {
  opacity: 0;
  transform: translateX(-14px);
}

.arrow2 {
  opacity: 1;
}

.arrow1-animation {
  opacity: 1;
  transform: translateX(0);
}

.arrow2-animation {
  transform: translateX(14px);
}

.arrow3-animation {
  opacity: 0;
  transform: translateX(14px);
}

@media (min-width: 0px) {
  .projects-container {
    width: 360px;
    margin-top: 96px;
    padding-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  .projects-container h2 {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
  }

  .project {
    width: 340px;
    margin-top: 32px;

    margin-left: auto;
    margin-right: auto;
    padding: 8px 12px;

    border: 2px solid var(--color-green);
    border-radius: 12px;
  }

  .project img {
    width: 314px;
    margin-top: 12px;
    filter: saturate(0);
  }

  .img-container {
    position: relative;
  }

  .banner {
    position: absolute;
    bottom: 8px;
    background-color: var(--color-black);
    filter: brightness(0.9);
    color: white;
    width: 100%;
    font-size: 20px;
    text-align: center;
    padding: 82px 0;
  }

  .img {
    transition: all 0.6s cubic-bezier(0.05, 0.25, 0.4, 1);
  }

  .img.animation {
    filter: saturate(100%);
    scale: 1.04;
  }

  .project h4 {
    text-align: center;
    font-size: 24px;
    margin-top: 16px;
  }

  .project-desc {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px 0 16px;
  }

  .read-more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 4px;
    margin: 16px 24px 12px 0;
    /* color: var(--color-green); */
  }

  .read-more p {
    color: var(--color-green);
  }

  .project-info a {
    text-decoration: none;
    color: var(--color-black);
  }

  /* .project-info a:hover {
    text-decoration: underline;
  } */

  .project-title {
    display: inline-block;
    position: relative;
    margin-left: calc((340px - 145.3px) / 2 - 12px);
  }

  .project-title:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: var(--color-black);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .project-title:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@media (min-width: 820px) {
  .projects-container {
    width: 560px;
  }

  .project {
    width: 560px;
    display: flex;
  }

  .project h4 {
    text-align: left;
    margin-left: 20px;
  }

  .project-desc {
    padding-left: 20px;
  }

  .read-more {
    margin-top: 32px;
    justify-content: flex-start;
    margin-top: 16px;
    padding-left: 18px;
  }
}

@media (min-width: 1025px) {
  .projects-container {
    width: 760px;
    margin-top: 0;
    padding-top: 108px;
  }

  .projects-container h2 {
    text-align: left;
    padding-left: 24px;
  }

  .project {
    width: 100%;
    padding: 24px 32px;
  }

  .project h2 {
    text-align: left;
  }

  .project img {
    width: 400px;
  }

  .banner {
    padding: 108px 0;
  }

  .project-info {
    margin-left: 12px;
  }

  .project h4 {
    font-size: 28px;
  }

  .read-more {
    justify-content: flex-start;
    padding-left: 18px;
    font-size: 20px;
  }
}
