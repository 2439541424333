@import "./vars.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Magra", "sans-serif";
}

body {
  background-color: var(--color-cream);
}

@font-face {
  font-family: "Magra";
  src: local("Magra"),
    url("./Helpers/MagraFont/Magra-Regular.ttf") format("truetype");
}

.centeredspinner {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 144px;
  width: 220px;
  height: 100vh;
}

.centeredspinner svg {
  margin-left: auto;
  margin-right: auto;
}
