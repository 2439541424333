@import "../vars.css";

.green {
  background-color: var(--color-green);
}
.black {
  background-color: var(--color-black);
}
.brown {
  background-color: var(--color-brown);
}
.cream {
  background-color: var(--color-cream);
}
.grey {
  background-color: var(--color-grey);
}

.birb {
  position: relative;
}

.eyeball {
  position: absolute;
  transform: rotate(30);
  transform-origin: bottom;
}

.beak-open {
  display: none;
}

@media (min-width: 0px) {
  .hero-container {
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 48px; */
    padding-top: 48px;
  }

  .hero-container h1 {
    font-weight: 500;
    font-size: 64px;
    margin-top: 12px;
  }

  .hero-container h3 {
    font-weight: 500;
    margin-top: 16px;
    line-height: 24px;
  }

  .squares {
    display: flex;
    column-gap: 10px;
  }

  .square {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 820px) {
  .hero-container {
    width: 560px;
    margin-top: 132px;
  }

  .hero-container h1 {
    font-size: 52px;
  }
}

@media (min-width: 1025px) {
  .hero-container {
    width: 520px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 184px;
    padding-top: 84px;
  }
} ;
