@media (min-width: 0px) {
  .footer {
    text-align: center;
    font-size: 16px;
  }

  .leaf {
    margin-top: 144px;
    width: 64px;
  }

  .message {
    margin-top: 32px;
  }

  .name {
    margin-top: 12px;
  }

  .dash {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media (min-width: 1025px) {
  .dash {
    margin-bottom: 32px;
  }
}
